import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
// import axios from 'axios';
import axios from "../utils/axiosConfig";
import { useCookies } from "react-cookie";
import Cookies from "js-cookie";
import ReactLogo from "../Primary_site_logo.jpg";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { WEB_URL } from "../utils/constants";
import LoaderProcessing from "../components/LoaderProcessing";
import axiosOrig from "axios";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href={WEB_URL}>
        AssessHub
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url('./../../images/login-page.svg')",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.type === "dark" ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    padding: theme.spacing(8, 4),
    display: "table-cell",
    verticalAlign: "middle",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignInSide(props) {
  const classes = useStyles();
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [errorMsg, setError] = useState("");
  const [cookies, setCookie] = useCookies(["token"]);
  const [showModel, setModelShow] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loginCheckModal, setLoginCheckModal] = useState(false);

  useEffect(() => {
    let routeURL = new URLSearchParams(window.location.search);
    let time = Number(routeURL.get("ref"));
    let objData = {
      email: routeURL.get("email") !== null ? atob(routeURL.get("email")) : null,
      password: "firstlogin",
    };

    let timeData = new Date(time).toLocaleDateString();

    let currentDate = new Date().toLocaleDateString();

    if (objData.email !== null && timeData === currentDate) {
      Cookies.set("email", objData.email.toLowerCase());
      axios
        .post("/login", {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Origin: "*",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Origin": "*",
          },
          userName: objData.email.toLowerCase(),
          password: objData.password,
          regen: true,
        })
        .then((userData) => {
          setError("");
          if (userData.data.status === "SUCCESS" || userData.data.status == "Token Regenerate complete successfully") {
            Cookies.set("token", userData.data.token, { path: "/" }); //expires: 7,
            Cookies.set("login", userData.data.token, { path: "/" });
            Cookies.set("role", userData.data.role, { path: "/" });
            Cookies.set("userId", userData.data.userId, { path: "/" });
            Cookies.set("sessionTime", "0");
            props.setUser(true);
          } else if (userData.data.status === "User Already logged in, logout to proceed further or contact support") {
            setModelShow(true);
            if (userData.data.userdetail === Cookies.get("login")) {
              Cookies.set("token", userData.data.userdetail, { path: "/" }); //expires: 7,
              Cookies.set("sessionTime", "0");
              props.setUser(true);
            } else {
              setError("");
            }
          } else if (userData.data.message === "you are already logged in") {
            setLoginCheckModal(true);
          } else {
            console.log("ere");
          }
        })
        .catch((error) => {
          console.log(">error", error);
        });
    } else {
    }
  }, []);

  const handleClose = () => {
    setModelShow(false);
    setLoginCheckModal(false);
  };

  const handleSession = () => {
    setError(props.forceLogout);
  };

  const handleReLogin = (event) => {
    setLoginCheckModal(false);
    handleSubmit(event, true);
  };

  var UserReLoginModal = () => {
    return (
      <Dialog
        PaperProps={{
          style: { borderRadius: "10px", textAlign: "center", maxWidth: "380px" },
        }}
        open={loginCheckModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ textAlign: "center" }}>
          <h3 id="transition-modal-title" style={{ color: "#6f2981", marginBottom: "30px" }}>
            System Warning
          </h3>
        </div>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span>You are already logged in on another device, Would you like to login to this device and logout from other device ?</span>
          </DialogContentText>

          <div className="form-group">
            <ul className="list-inline btn-list">
              <li>
                <a className="btn btn-blue" onClick={handleReLogin}>
                  Yes
                </a>
              </li>
              <li>
                <a className="btn btn-blue" onClick={handleClose} autoFocus>
                  No
                </a>
              </li>
            </ul>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  const handleSubmit = async (event, regen) => {
    setLoading(true);
    setError("");
    //alert('An essay was submitted: ' + this.state.value);
    event.preventDefault();
    const res = await axiosOrig.get("https://api.ipify.org/?format=json");
    // console.log(res.data);
    Cookies.set("email", email.toLowerCase());
    axios
      .post("/login", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Origin: "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
        },
        userName: email.toLowerCase(),
        password,
        regen: regen,
        ip: res.data.ip,
      })
      .then((userData) => {
        setLoading(false);
        if (userData.data.remainingAttempts != undefined) {
          setError(userData.data.remainingAttempts > 1 ?
            <span>Username or Password is incorrect.</span>
            :
            userData.data.remainingAttempts > 0 ?
              <span>One last attempt remaining !!<br />WARNING : Too many unsuccessful attempts can cause the account to lock out !!</span>
              :
              <span>This account is locked due to too many unsuccessful attempts. Please try again after some time.</span>
          )
          return
        }
        if (userData.data.status === "SUCCESS" || userData.data.status == "Token Regenerate complete successfully") {
          // console.log(">userData.data",userData.data)
          Cookies.set("token", userData.data.token, { path: "/" }); //expires: 7,
          Cookies.set("login", userData.data.token, { path: "/" });
          Cookies.set("role", userData.data.role, { path: "/" });
          Cookies.set("userId", userData.data.userId, { path: "/" });
          Cookies.set("sessionTime", "0");
          props.setUser(true);
          //props.history.push('/user/dashboard/');
        } else if (userData.data.status === "User Already logged in, logout to proceed further or contact support") {
          setModelShow(true);

          // Cookies.set('token', userData.data.token, { path: '/' });//expires: 7,
          // Cookies.set('login', userData.data.token, { path: '/' })
          // props.setUser(true);
          if (userData.data.userdetail === Cookies.get("login")) {
            Cookies.set("token", userData.data.userdetail, { path: "/" }); //expires: 7,
            Cookies.set("sessionTime", "0");
            props.setUser(true);
          } else {
            setError("");
          }
        } else if (userData.data.message === "you are already logged in") {
          setLoginCheckModal(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        //, error.response.data
        setError(error.response.data.error);
      });
  };

  return (
    <>
      {" "}
      <UserReLoginModal />
      <Grid container component="main" id="main-body-two" className={classes.root} onLoad={handleSession}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square style={{ display: "table" }}>
          <div className={classes.paper}>
            {/* <Avatar className={classes.avatar}>
            <LockOutlinedIcon /> */}
            <img className="img-responsive center-block" style={{ maxWidth: "450px", maxHeight: "70px" }} src={require("../images/assesshub logo green dot.svg")} alt="React Logo" />
            {/* </Avatar> */}
            {/* <Typography component="h1" variant="h5">
            Sign in
          </Typography> */}
            <form className={classes.form} onSubmit={handleSubmit} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Username"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(event) => setEmail(event.target.value.toLowerCase())}
              />

              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(event) => setPassword(event.target.value)}
              />
              {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
              <Typography style={{ color: "red" }}>{errorMsg}</Typography>
              <Dialog
                PaperProps={{
                  style: { borderRadius: "10px", textAlign: "center", maxWidth: "380px" },
                }}
                open={showModel}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <div style={{ textAlign: "center" }}>
                  <h3 id="transition-modal-title" style={{ color: "#6f2981", marginBottom: "30px" }}>
                    System Warning
                  </h3>
                </div>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <span>The session is active. Do you want to re-login to the system?</span>
                  </DialogContentText>

                  <div className="form-group">
                    <ul className="list-inline btn-list">
                      <li>
                        <a className="btn btn-blue" onClick={handleReLogin}>
                          Yes
                        </a>
                      </li>
                      <li>
                        <a className="btn btn-blue" onClick={handleClose} autoFocus>
                          No
                        </a>
                      </li>
                    </ul>
                  </div>
                </DialogContent>
              </Dialog>

              {loading ? (
                <div className="loaderProcess" style={{ margin: "24px 0px 16px" }}>
                  <LoaderProcessing />
                </div>
              ) : (
                <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                  Sign In
                </Button>
              )}
              {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
