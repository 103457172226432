// // -------------------------------------------------------------------------
// import React, { useEffect, useState } from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import converter from "convert-seconds";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: "flex",
//   },
//   paper: {
//     padding: theme.spacing(2),
//     textAlign: "center",
//     color: theme.palette.text.secondary,
//   },
//   title: {
//     flexGrow: 1,
//   },
// }));

// export default (props) => {
//   const {
//     showModel,
//     examDetail,
//     setTimeoutModelShow,
//     setTimeoutModelOneMinuteShow,
//     ImageAlertShowModal,
//     AudioAlertShowModal,
//     NoFaceAlertModal,
//     NoMatchAlertModal,
//     MultipleFaceAlertModal,
//     isVideoUploading,
//     isTimeUp,
//     setIsTimeUp,
//     isVideoInterviewTest,
//     isRecordingDone,
//   } = props;

//   const [timeLeft, setTimeLeft] = useState(examDetail[0].exam_end_time - Math.floor(Date.now() / 1000));

//   useEffect(() => {
//     const timer = setInterval(() => {
//       const now = Math.floor(Date.now() / 1000); // Get current timestamp
//       const remainingTime = examDetail[0].exam_end_time - now;

//       setTimeLeft(remainingTime > 0 ? remainingTime : 0);

//       if (remainingTime === 60) setTimeoutModelOneMinuteShow(true);
//       if (remainingTime === 0) {
//         setIsTimeUp(true);
//         setTimeoutModelShow(true);
//         clearInterval(timer);
//       }

//       localStorage.setItem("remainingTime", remainingTime);
//     }, 1000);

//     return () => clearInterval(timer);
//   }, [examDetail]);

//   const displayTime = converter(timeLeft);

//   return (
//     <>
//       {timeLeft > 0 ? (
//         <span>
//           {`${displayTime.hours.toString().padStart(2, "0")} :
//             ${displayTime.minutes.toString().padStart(2, "0")} :
//             ${displayTime.seconds.toString().padStart(2, "0")}`}
//         </span>
//       ) : (
//         <span>Time's up!</span>
//       )}
//     </>
//   );
// };

// -------------------------------------------------------------------------------------------------

// import React, { useEffect, useState } from "react";
// import { makeStyles, useTheme } from "@material-ui/core/styles";
// import converter from "convert-seconds";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: "flex",
//   },
//   paper: {
//     padding: theme.spacing(2),
//     textAlign: "center",
//     color: theme.palette.text.secondary,
//   },
//   title: {
//     flexGrow: 1,
//   },
// }));

// let d = Date.now(); // UTC time
// console.log("utc time: ".d);

// export default (props) => {
//   const {
//     showModel,
//     examDetail,
//     setTimeoutModelShow,
//     setTimeoutModelOneMinuteShow,
//     ImageAlertShowModal,
//     AudioAlertShowModal,
//     NoFaceAlertModal,
//     NoMatchAlertModal,
//     MultipleFaceAlertModal,
//     isVideoUploading,
//     isTimeUp,
//     setIsTimeUp,
//     isVideoInterviewTest,
//     isRecordingDone,
//     utcTestFinishTime,
//     setUtcTestFinishTime,
//   } = props;

//   const calculateTimeLeft = () => {
//     let timeLeft = {};
//     //const
//     if (examDetail[0].time_remaining > 0) {
//       timeLeft = {
//         hours: converter(examDetail[0].time_remaining).hours,
//         minutes: converter(examDetail[0].time_remaining).minutes,
//         seconds: converter(examDetail[0].time_remaining).seconds,
//       };
//       //

//       if (
//         !isVideoUploading &&
//         !showModel &&
//         !ImageAlertShowModal &&
//         !AudioAlertShowModal &&
//         !NoFaceAlertModal &&
//         !NoMatchAlertModal &&
//         !MultipleFaceAlertModal
//       ) {
//         examDetail[0].time_remaining = examDetail[0].time_remaining - 0.5;
//       }

//       if (examDetail[0].time_remaining === 60) {
//         setTimeoutModelOneMinuteShow(true);
//       }
//       examDetail[0].time_left = examDetail[0].time_remaining;

//       if (timeLeft.hours === "00" && timeLeft.minutes === "00" && timeLeft.seconds === "00") {
//         setTimeoutModelShow(true);
//       }
//       if (timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds === 0) {
//         setIsTimeUp(true);
//       }
//     }

//     return timeLeft;
//   };
//   const calculateTimeLeftOnLoad = () => {
//     //
//     //const difference = +new Date(d + examDetail[0].time_remaining * 1000) - +new Date();
//     //

//     let timeLeft = {};
//     //const
//     if (examDetail[0].time_remaining > 0) {
//       timeLeft = {
//         hours: converter(examDetail[0].time_remaining).hours,
//         minutes: converter(examDetail[0].time_remaining).minutes,
//         seconds: converter(examDetail[0].time_remaining).seconds,
//       };
//       //
//       if (examDetail[0].time_remaining === 60) {
//         setTimeoutModelOneMinuteShow(true);
//       }
//       examDetail[0].time_left = examDetail[0].time_remaining;

//       if (timeLeft.hours === "00" && timeLeft.minutes === "00" && timeLeft.seconds === "00") {
//         setTimeoutModelShow(true);
//       }
//       if (timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds === 0) {
//         setIsTimeUp(true);
//       }
//     }

//     return timeLeft;
//   };

//   const [timeLeft, setTimeLeft] = useState(calculateTimeLeftOnLoad());
//   const [timeSpent, setTimeSpent] = useState(0);

//   useEffect(() => {
//     let timer;
//     if (!isVideoUploading) {
//       timer = setTimeout(() => {
//         setTimeLeft(calculateTimeLeft());
//         setTimeSpent((timeSpent + 1) / 2);
//         localStorage.setItem("remainingTime", examDetail[0].time_remaining - timeSpent);
//       }, 1000);
//     }
//     return () => clearTimeout(timer);
//   }, [timeLeft, isVideoUploading]);

// useEffect(() => {
//   if (isVideoInterviewTest && isTimeUp && isRecordingDone && !isVideoUploading) {
//     setTimeoutModelShow(true);
//     setTimeoutModelOneMinuteShow(true);
//   }
// }, [isVideoInterviewTest, isTimeUp, isVideoUploading, isRecordingDone]);

//   const timerComponents = [];

//   Object.keys(timeLeft).forEach((interval) => {
//     if (!timeLeft[interval]) {
//       return;
//     }

//     timerComponents.push(
//       <span>
//         {`${timeLeft["hours"] == 0 ? "00" : timeLeft["hours"].toString().length == 2 ? timeLeft["hours"] : "0" + timeLeft["hours"]} : ${
//           timeLeft["minutes"] == 0 ? "00" : timeLeft["minutes"].toString().length == 2 ? timeLeft["minutes"] : "0" + timeLeft["minutes"]
//         } : ${timeLeft["seconds"] == 0 ? "00" : timeLeft["seconds"].toString().length == 2 ? timeLeft["seconds"] : "0" + timeLeft["seconds"]} `}
//       </span>
//     );
//   });

//   return (
//     <>
//       {timerComponents.length ? (
//         timerComponents[0]
//       ) : (
//         <>
//           <span>Time's up!</span>
//           <>
//             {setTimeoutModelShow(true)}
//             {setTimeoutModelOneMinuteShow(true)}
//           </>
//         </>
//       )}
//     </>
//   );
// };

// -------------------------------------------------------------------------------------------------

// GOOD :::::::::: WORKING CODE
// import React, { useEffect, useState } from "react";
// import converter from "convert-seconds";

// export default function Timer(props) {
//   const {
//     showModel,
//     examDetail,
//     setTimeoutModelShow,
//     setTimeoutModelOneMinuteShow,
//     ImageAlertShowModal,
//     AudioAlertShowModal,
//     NoFaceAlertModal,
//     NoMatchAlertModal,
//     MultipleFaceAlertModal,
//     isVideoUploading,
//     isTimeUp,
//     setIsTimeUp,
//     isVideoInterviewTest,
//     isRecordingDone,
//     utcTestStartTime,
//     utcTestFinishTime,
//   } = props;

//   console.log("utc finish time", utcTestFinishTime);
//   console.log("utc start time", utcTestStartTime);
//   console.log("difference:", utcTestFinishTime - utcTestStartTime);

//   const getTimeLeft = () => {
//     // const remainingSeconds = utcTestFinishTime - utcTestStartTime;

//     const nowUtc = Math.floor(Date.now() / 1000); // Get current UTC time
//     const remainingSeconds = Math.max(utcTestFinishTime - nowUtc, 0); // Prevent negative values

//     console.log("remaining seconds", remainingSeconds);

//     return {
//       hours: String(converter(remainingSeconds).hours).padStart(2, "0"),
//       minutes: String(converter(remainingSeconds).minutes).padStart(2, "0"),
//       seconds: String(converter(remainingSeconds).seconds).padStart(2, "0"),
//     };
//   };

//   const [timeLeft, setTimeLeft] = useState(getTimeLeft());

//   useEffect(() => {
//     let timer;

//     if (!isVideoUploading) {
//       timer = setInterval(() => {
//         const newTimeLeft = getTimeLeft();
//         setTimeLeft(newTimeLeft);

//         // Save remaining time in local storage
//         localStorage.setItem("remainingTime", `${newTimeLeft.hours}:${newTimeLeft.minutes}:${newTimeLeft.seconds}`);

//         // Handle timeout scenarios
//         if (newTimeLeft.hours === "00" && newTimeLeft.minutes === "01" && newTimeLeft.seconds === "00") {
//           setTimeoutModelOneMinuteShow(true);
//         }

//         if (newTimeLeft.hours === "00" && newTimeLeft.minutes === "00" && newTimeLeft.seconds === "00") {
//           console.log("inside here");
//           setTimeoutModelShow(true);
//           setIsTimeUp(true);
//           clearInterval(timer);
//         }
//       }, 1000);
//     }

//     return () => clearInterval(timer);
//   }, [timeLeft, isVideoUploading]);

//   useEffect(() => {
//     if (isVideoInterviewTest && isTimeUp && isRecordingDone && !isVideoUploading) {
//       setTimeoutModelShow(true);
//       setTimeoutModelOneMinuteShow(true);
//     }
//   }, [isVideoInterviewTest, isTimeUp, isVideoUploading, isRecordingDone]);

//   return (
//     <>
//       {timeLeft.hours === "00" && timeLeft.minutes === "00" && timeLeft.seconds === "00" ? (
//         <span>Time's up!</span>
//       ) : (
//         <span>{`${timeLeft.hours} : ${timeLeft.minutes} : ${timeLeft.seconds}`}</span>
//       )}
//     </>
//   );
// }

// ------------------------------------------------------------------------------------------------

// import React, { useEffect, useState } from "react";
// import converter from "convert-seconds";

// export default function Timer(props) {
//   const {
//     showModel,
//     examDetail,
//     setTimeoutModelShow,
//     setTimeoutModelOneMinuteShow,
//     ImageAlertShowModal,
//     AudioAlertShowModal,
//     NoFaceAlertModal,
//     NoMatchAlertModal,
//     MultipleFaceAlertModal,
//     isVideoUploading,
//     isTimeUp,
//     setIsTimeUp,
//     isVideoInterviewTest,
//     isRecordingDone,
//     utcTestStartTime,
//     utcTestFinishTime,
//   } = props;

//   // Calculate total remaining time initially (in seconds)
//   const totalDuration = Math.max(utcTestFinishTime - utcTestStartTime, 0);
//   const [remainingSeconds, setRemainingSeconds] = useState(totalDuration);

//   console.log("remaining seconds:", remainingSeconds);

//   // Convert seconds into HH:MM:SS format
//   const getTimeLeft = () => {
//     return {
//       hours: String(converter(remainingSeconds).hours).padStart(2, "0"),
//       minutes: String(converter(remainingSeconds).minutes).padStart(2, "0"),
//       seconds: String(converter(remainingSeconds).seconds).padStart(2, "0"),
//     };
//   };

//   useEffect(() => {
//     let timer;

//     // if (!isVideoUploading) {
//     //   timer = setInterval(() => {
//     //     setRemainingSeconds((prev) => {
//     //       if (prev <= 1) {
//     //         clearInterval(timer);
//     //         setIsTimeUp(true);
//     //         setTimeoutModelShow(true);
//     //         return 0;
//     //       }
//     //       return prev - 1;
//     //     });
//     //   }, 1000);
//     // }

//     if (!isVideoUploading) {
//       timer = setInterval(() => {
//         setRemainingSeconds((prev) => {
//           console.log("prev::", prev);

//           examDetail[0].time_remaining = prev;
//           examDetail[0].time_left = prev;

//           if (prev <= 1) {
//             clearInterval(timer);
//             setIsTimeUp(true);
//             setTimeoutModelShow(true);
//             return 0;
//           }

//           const newTimeLeft = prev - 1;

//           // Save remaining time in local storage
//           const formattedTime = getTimeLeft();
//           localStorage.setItem("remainingTime", `${formattedTime.hours}:${formattedTime.minutes}:${formattedTime.seconds}`);

//           return newTimeLeft;
//         });
//       }, 1000);
//     }

//     return () => clearInterval(timer);
//   }, [isVideoUploading]);

//   useEffect(() => {
//     if (isVideoInterviewTest && isTimeUp && isRecordingDone && !isVideoUploading) {
//       setTimeoutModelShow(true);
//       setTimeoutModelOneMinuteShow(true);
//     }
//   }, [isVideoInterviewTest, isTimeUp, isVideoUploading, isRecordingDone]);

//   const timeLeft = getTimeLeft();

//   return <>{remainingSeconds === 0 ? <span>Time's up!</span> : <span>{`${timeLeft.hours} : ${timeLeft.minutes} : ${timeLeft.seconds}`}</span>}</>;
// }

// ------------------------------------------

/*
// FINAL CODE
import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import converter from "convert-seconds";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  title: {
    flexGrow: 1,
  },
}));

export default (props) => {
  const {
    showModel,
    examDetail,
    setTimeoutModelShow,
    setTimeoutModelOneMinuteShow,
    ImageAlertShowModal,
    AudioAlertShowModal,
    NoFaceAlertModal,
    NoMatchAlertModal,
    MultipleFaceAlertModal,
    isVideoUploading,
    isTimeUp,
    setIsTimeUp,
    isVideoInterviewTest,
    isRecordingDone,
    utcTestStartTime,
    utcTestFinishTime,
  } = props;

  console.log("remaining seconds 1", Math.max(utcTestFinishTime - utcTestStartTime, 0));
  const [remainingSeconds, setRemainingSeconds] = useState(Math.max(utcTestFinishTime - utcTestStartTime, 0));

  const getTimeLeft = () => ({
    hours: String(converter(remainingSeconds).hours).padStart(2, "0"),
    minutes: String(converter(remainingSeconds).minutes).padStart(2, "0"),
    seconds: String(converter(remainingSeconds).seconds).padStart(2, "0"),
  });

  useEffect(() => {
    let timer;

    if (!isVideoUploading) {
      timer = setInterval(() => {
        setRemainingSeconds(prevSeconds => {
          if (prevSeconds <= 1) {
            clearInterval(timer);
            setIsTimeUp(true);
            setTimeoutModelShow(true);
            return 0;
          } else {
            const newTime = prevSeconds - 1;
            console.log("newTime:", newTime);
            // Save remaining time in local storage and examDetail
            localStorage.setItem("remainingTime", newTime.toString());
            if (examDetail && examDetail[0]) {
              examDetail[0].remaining_time = newTime;
            }
            return newTime;
          }
        });
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [isVideoUploading, setIsTimeUp, setTimeoutModelShow, examDetail]);

  useEffect(() => {
    if (isVideoInterviewTest && isTimeUp && isRecordingDone && !isVideoUploading) {
      setTimeoutModelShow(true);
      setTimeoutModelOneMinuteShow(true);
    }
  }, [isVideoInterviewTest, isTimeUp, isVideoUploading, isRecordingDone, setTimeoutModelShow, setTimeoutModelOneMinuteShow]);

  const timeLeft = getTimeLeft();

  return <>{remainingSeconds === 0 ? <span>Time's up!</span> : <span>{`${timeLeft.hours} : ${timeLeft.minutes} : ${timeLeft.seconds}`}</span>}</>;
};

*/

import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import converter from "convert-seconds";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  title: {
    flexGrow: 1,
  },
}));

export default (props) => {
  const {
    showModel,
    examDetail,
    setTimeoutModelShow,
    setTimeoutModelOneMinuteShow,
    ImageAlertShowModal,
    AudioAlertShowModal,
    NoFaceAlertModal,
    NoMatchAlertModal,
    MultipleFaceAlertModal,
    isVideoUploading,
    isTimeUp,
    setIsTimeUp,
    isVideoInterviewTest,
    isRecordingDone,
    utcTestStartTime,
    utcTestFinishTime,
  } = props;

  // Use a ref for tracking the last time update to handle drift
  const lastUpdateTimeRef = useRef(Date.now());

  const [remainingSeconds, setRemainingSeconds] = useState(Math.max(utcTestFinishTime - utcTestStartTime, 0));

  // Track if timer is active using a ref to avoid re-renders
  const isActiveRef = useRef(!isVideoUploading);

  const getTimeLeft = () => ({
    hours: String(converter(remainingSeconds).hours).padStart(2, "0"),
    minutes: String(converter(remainingSeconds).minutes).padStart(2, "0"),
    seconds: String(converter(remainingSeconds).seconds).padStart(2, "0"),
  });

  // Throttle localStorage writes (only update every 5 seconds)
  const updateStorage = (newTime) => {
    if (newTime % 5 === 0 || newTime <= 60) {
      // Update more frequently in the last minute
      localStorage.setItem("remainingTime", newTime.toString());
      if (examDetail && examDetail[0]) {
        examDetail[0].remaining_time = newTime;
      }
    }
  };

  useEffect(() => {
    // Update active status when video uploading changes
    isActiveRef.current = !isVideoUploading;
  }, [isVideoUploading]);

  useEffect(() => {
    let timer;
    let animationFrameId;

    const updateTimer = () => {
      if (!isActiveRef.current) {
        animationFrameId = requestAnimationFrame(updateTimer);
        return;
      }

      const now = Date.now();
      const deltaSeconds = Math.floor((now - lastUpdateTimeRef.current) / 1000);

      if (deltaSeconds >= 1) {
        lastUpdateTimeRef.current = now - (deltaSeconds % 1000); // Adjust for drift

        setRemainingSeconds((prevSeconds) => {
          const newTime = Math.max(0, prevSeconds - deltaSeconds);

          if (prevSeconds > 0 && newTime <= 0) {
            setIsTimeUp(true);
            setTimeoutModelShow(true);
            updateStorage(0);
            return 0;
          }

          updateStorage(newTime);
          return newTime;
        });
      }

      animationFrameId = requestAnimationFrame(updateTimer);
    };

    // Start the timer using requestAnimationFrame for better accuracy
    animationFrameId = requestAnimationFrame(updateTimer);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [setIsTimeUp, setTimeoutModelShow, examDetail]);

  useEffect(() => {
    if (examDetail[0].remaining_time == 60 && examDetail[0].remaining_time > 59) {
      setTimeoutModelOneMinuteShow(true);
    }
  });

  useEffect(() => {
    if (isVideoInterviewTest && isTimeUp && isRecordingDone && !isVideoUploading) {
      setTimeoutModelShow(true);
      setTimeoutModelOneMinuteShow(true);
    }
  }, [isVideoInterviewTest, isTimeUp, isVideoUploading, isRecordingDone, setTimeoutModelShow, setTimeoutModelOneMinuteShow]);

  const timeLeft = getTimeLeft();

  return <>{remainingSeconds === 0 ? <span>Time's up!</span> : <span>{`${timeLeft.hours} : ${timeLeft.minutes} : ${timeLeft.seconds}`}</span>}</>;
};
